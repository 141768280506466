/*! 
 * (C)Mynavi Corporation
 */

/** ============================================================
 * INIT
 * ========================================================== */
/** ------------------------------
 * 依存ファイルの読み込み
 * ---------------------------- */
import jQuery from 'jquery';
const $ = jQuery; //jQueryのインポートとドルマークの参照代入
import lazyload from 'lazyload';
import 'picturefill';

/** ------------------------------
 * windowoオブジェクトに登録してグローバルに使えるように
 * ---------------------------- */
//jQuery
window.jq = jQuery;

//状態監視用
window.mnstate = window.mnstate || {};


/** ------------------------------
 * @namespace
 * @global
 * ---------------------------- */
var MNF = MNF || {};


/**
 * 状態
 */
// MNF.state = {
//   /**
//    * モバイルのアクセスであるかを示す真偽値
//    * @type {boolean}
//    */
//   isMobile: true,
// };

/**
 * UI
 */
//import ui from './ui';
//MNF.ui = ui;

//window.MNF = MNF;

//alert("ddcw:" + document.documentElement.clientWidth + " wiw:" + window.innerWidth + " sw" + screen.width + "")

/* ////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////// */
/** ============================================================
 * DOMが読み込まれたときの処理
 * ========================================================== */
window.addEventListener('load', function(){

  MNF.chkMobile(); //モバイルのチェック
  MNF.chkUA(); //UAチェック

  let images = document.querySelectorAll("img:not(.js--no__lazy)");
  new lazyload(images);

}, false);


/* ////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////// */
/** ============================================================
 * リサイズの処理
 * ========================================================== */
let queue = null,
wait = 300;
window.addEventListener( 'resize', function(){
  clearTimeout( queue );
  queue = setTimeout(function() {
    MNF.chkMobile(); //モバイルのチェック
    MNF.chkUA(); //UAチェック
  }, wait );
}, false );



/* ////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////// */
/** ============================================================
 * Functions
 * ========================================================== */

/** ------------------------------
 * 状態のチェック
 * モバイルかどうかをUAで判定
 * update 2020/03/09 UAは使わなくなったので画面幅に変更
 * @return {boolean} window.mnstate.isMobile
 * ---------------------------- */
MNF.chkMobile = function () {
  // const ua = navigator.userAgent;
  // let bool = false;
  // if (ua.indexOf('iPhone') > -1 || (ua.indexOf('Android') > -1 && ua.indexOf('Mobile') > -1)) {
  //   bool = true;
  //   MNF.state.isMobile = bool;
  //   window.mnstate.isMobile = bool;
  //   return;
  // } else {
  //   MNF.state.isMobile = bool;
  //   window.mnstate.isMobile = bool;
  //   return;
  // }
  var deviceWidth = window.innerWidth;
  //var deviceWidth = screen.width;
  
  var bool = false;
  if (deviceWidth < 768) {
    bool = true;
    //MNF.state.isMobile = bool;
    window.mnstate.isMobile = bool;
    return;
  } else {
    //MNF.state.isMobile = bool;
    window.mnstate.isMobile = bool;
    return;
  }
}


MNF.chkUA = function () {
  var ua = navigator.userAgent;
  if((ua.indexOf('iPhone') > 0) || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0 || ua.indexOf('iPad') > 0){
    $('head').prepend('<meta name="viewport" content="width=device-width,initial-scale=1">');
  } else {
    $('head').prepend('<meta name="viewport" content="width=1200">');
  }
}






/* ////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////// */
/** ============================================================
 * 要素が全て読み込まれたときの処理
 * ========================================================== */
window.addEventListener( 'load', function(){
  //MNF.ui.pageTop(MNF.state.isMobile); // ページ内スクロール
  //MNF.ui.gnavi(); // ページ内スクロール
});


